import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { buttonfieldStyle, colorPalette } from "../themeGlobal";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  localStorage.clear();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: colorPalette.secondaryLight,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        Aradığınız sayfa mevcut değil.
      </Typography>
      <br />
      <Button
        variant="contained"
        sx={buttonfieldStyle}
        onClick={() => navigate("/")}
      >
        Geri Git
      </Button>
    </Box>
  );
};

export default NotFound;
