import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { colorPalette } from "./themeGlobal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SignUp } from "./Pages/SignUp";
import { SignIn } from "./Pages/SignIn";
// import { useAuthentication } from "./store";
import { Panel } from "./Pages/Panel";
import { EmailVerification } from "./Pages/EmailVerification";
import { PasswordVerification } from "./Pages/PasswordVerification";
import { Aydinlatma } from "./LandingPage/components/Documents/AydinlatmaMetni";
import { Gizlilik } from "./LandingPage/components/Documents/GizlilikPolitikasi";
import { Kullanim } from "./LandingPage/components/Documents/KullanimKosullari";
import NotFound from "./Pages/NotFound";
import { LazyComponent } from "./lazyWithRetry";
import CheckCode from "./Pages/CheckCode";

const Landing = LazyComponent(() => import("./LandingPage/App"));
const Main = LazyComponent(() => import("./Pages/Main"));

const App = () => {
  // const isLoggedIn = useAuthentication((state) => state.isLoggedIn);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <div style={{ backgroundColor: colorPalette.primary }}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/email" element={<EmailVerification />} />
            <Route path="/password" element={<PasswordVerification />} />
            <Route path="/check-code" element={<CheckCode />} />

            {/* Routes with specific IDs */}
            <Route path="/panel/:id" element={<Panel />} />
            <Route path="/:id" element={<Main />} />

            <Route path="/aydinlatma-metni" element={<Aydinlatma />} />
            <Route path="/gizlilik-politikasi" element={<Gizlilik />} />
            <Route path="/kullanim-kosullari" element={<Kullanim />} />

            {/* 404 Not Found */}
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
