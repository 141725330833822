import { CircularProgress } from "@mui/material";
import React from "react";

export function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Horizontal centering
        alignItems: "center", // Vertical centering
        height: "100vh", // Optionally, make the container full viewport height
      }}
    >
      <CircularProgress />
    </div>
  );
}
