import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { colorPalette, ip, radioButton } from "../themeGlobal";
import { MuiTelInput } from "mui-tel-input";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useUserDataStore from "../Requests/requestUser";
import useAuthStore from "../Requests/requestAuth";
import Logo from "../LandingPage/images/Logo.png";
import { Loading } from "../Components/Loading";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colorPalette.secondaryLight,
              borderWidth: "2px",
            },
            "&:hover fieldset": {
              borderColor: colorPalette.secondaryLight,
              borderWidth: "2px",
            },
          },
        },
      },
    },
  },
});

export function Panel() {
  const { userData, isLoading, fetchUserData } = useUserDataStore();
  const { logout } = useAuthStore();

  const [phoneVal, setPhone] = useState("");
  const [whatsappVal, setWhatsapp] = useState("");
  const [imageString, setImageString] = useState(null);
  const [check, setCheck] = useState({});
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const navigate = useNavigate();

  const handleUpdateUser = async (dataToSend) => {
    try {
      setIsLoadingUpdate(true);
      const response = await fetch(
        `${ip}/users/${localStorage.getItem("userId")}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Replace with your actual access token
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to update user data: ${response.status}`);
      }

      const data = await response.json();

      setIsLoadingUpdate(false);
    } catch (error) {
      console.error("Error updating user data:", error);
      setIsLoadingUpdate(false);
    }
    navigate(`/${localStorage.getItem("userId")}`);
  };

  useEffect(() => {
    if (userData?.userData?.phone) {
      setPhone(userData.userData.phone);
    }
    if (userData?.userData?.whatsapp) {
      setWhatsapp(userData.userData.whatsapp);
    }
    if (userData?.userData?.image) {
      setImageString(userData.userData.image);
    }
    if (userData?.userData?.check) {
      setCheck(userData.userData.check);
    }
    if (check.instagram === undefined) {
      setCheck(() => ({
        image: true,
        phone: true,
        whatsapp: true,
        isim: true,
        baslik: true,
        email: true,
        facebook: true,
        instagram: true,
        twitter: true,
        linkedIn: true,
        plaka: true,
        marka: true,
        model: true,
      }));
    }
  }, [userData]);

  useEffect(() => {
    localStorage.getItem("userId")
      ? fetchUserData(localStorage.getItem("userId"))
      : navigate("/sign-in");
  }, []);

  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      image: imageString,
      phone: phoneVal,
      whatsapp: whatsappVal,
      isim: data.get("isim"),
      baslik: data.get("baslik"),
      email: data.get("email"),
      facebook: data.get("facebook"),
      instagram: data.get("instagram"),
      twitter: data.get("twitter"),
      linkedIn: data.get("linkedIn"),
      plaka: data.get("plaka"),
      marka: data.get("marka"),
      model: data.get("model"),
      check: {
        image: check.image,
        phone: check.phone,
        whatsapp: check.whatsapp,
        isim: check.isim,
        baslik: check.baslik,
        email: check.email,
        facebook: check.facebook,
        instagram: check.instagram,
        twitter: check.twitter,
        linkedIn: check.linkedIn,
        plaka: check.plaka,
        marka: check.marka,
        model: check.model,
      },
    };

    handleUpdateUser(userData);
  }

  const handleCheck = (event) => {
    const { name, checked } = event.target;

    setCheck((prevCheck) => ({
      ...prevCheck,
      [name]: checked,
    }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageString(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function Logout() {
    logout();
    navigate(`/${localStorage.getItem("userId")}`);
    localStorage.clear();
  }

  return (
    <Container fixed sx={{ bgcolor: colorPalette.primary }}>
      {isLoading || isLoadingUpdate ? (
        <Loading />
      ) : (
        <ThemeProvider theme={theme}>
          <Box
            component="form"
            onSubmit={submitHandler}
            sx={{
              bgcolor: colorPalette.primary,
              height: "100%",
              width: "100%",
              //   border: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="30px"
            >
              <Button
                onClick={() => navigate(`/${localStorage.getItem("userId")}`)}
              >
                <ArrowBackIcon />
              </Button>
              <Paper
                elevation={3}
                style={{
                  padding: 20,
                  backgroundColor: colorPalette.primary,
                  width: "50%",
                }}
              >
                <img src={Logo} alt="Logo" style={{ width: "100%" }} />
              </Paper>
              <Button onClick={Logout}>
                <LogoutIcon />
              </Button>
            </Box>
            <br />
            <br />
            <Box width="100%">
              <Divider sx={{ borderBottomWidth: 3 }} />
            </Box>
            <br />

            <Grid
              container
              spacing={2}
              sx={{ width: "100%", height: "100%", m: "10px" }}
            >
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>Fotoğraf</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <Card sx={{ height: "70%" }}>
                  {imageString && (
                    <CardMedia
                      component="img"
                      alt="Image"
                      height="80"
                      image={imageString}
                    />
                  )}
                </Card>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    ml: "20%",
                    width: "50px",
                    height: "40px",
                  }}
                  style={{
                    color: colorPalette.primary,
                    backgroundColor: colorPalette.secondary,
                  }}
                >
                  Yükle
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </Button>
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="image"
                  checked={check.image}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>İsim</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="isim"
                  defaultValue={userData?.userData?.isim}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="isim"
                  checked={check.isim}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>Başlık</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="baslik"
                  defaultValue={userData?.userData?.baslik}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="baslik"
                  checked={check.baslik}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
                <Divider sx={{ borderBottomWidth: 3 }} />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>Plaka</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="plaka"
                  defaultValue={userData?.userData?.plaka}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="plaka"
                  checked={check.plaka}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>Marka</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="marka"
                  defaultValue={userData?.userData?.marka}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="marka"
                  checked={check.marka}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <Typography color={colorPalette.secondary}>Model</Typography>
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="model"
                  defaultValue={userData?.userData?.model}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="model"
                  checked={check.model}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
                <Divider sx={{ borderBottomWidth: 3 }} />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  width: "100%",
                  height: "100px",
                  ...center,
                }}
              >
                <CallIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid
                item
                xs={7}
                sx={{
                  width: "100%",
                  ...center,
                }}
              >
                <MuiTelInput
                  defaultCountry="TR"
                  onlyCountries={["TR"]}
                  disableDropdown
                  forceCallingCode
                  value={phoneVal}
                  onChange={(e) => setPhone(e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="phone"
                  checked={check.phone}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <WhatsAppIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <MuiTelInput
                  defaultCountry="TR"
                  onlyCountries={["TR"]}
                  disableDropdown
                  forceCallingCode
                  value={whatsappVal}
                  onChange={(e) => setWhatsapp(e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="whatsapp"
                  checked={check.whatsapp}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <EmailIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  name="email"
                  defaultValue={userData?.userData?.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="email"
                  checked={check.email}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%", height: "100%" }}>
                <Divider sx={{ borderBottomWidth: 3 }} />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <FacebookIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  label="@username"
                  name="facebook"
                  defaultValue={userData?.userData?.facebook}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="facebook"
                  checked={check.facebook}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <InstagramIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  label="@username"
                  name="instagram"
                  defaultValue={userData?.userData?.instagram}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="instagram"
                  checked={check.instagram}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <TwitterIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  label="@username"
                  name="twitter"
                  defaultValue={userData?.userData?.twitter}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="twitter"
                  checked={check.twitter}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ width: "100%", height: "100px", ...center }}
              >
                <LinkedInIcon style={{ color: colorPalette.secondary }} />
              </Grid>
              <Grid item xs={7} sx={{ width: "100%", ...center }}>
                <TextField
                  label="@username"
                  name="linkedIn"
                  defaultValue={userData?.userData?.linkedIn}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ width: "100%", ...center }}>
                <Switch
                  name="linkedIn"
                  checked={check.linkedIn}
                  onChange={handleCheck}
                  style={radioButton}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "20px",
                }}
              >
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    color: colorPalette.primary,
                    backgroundColor: colorPalette.secondary,
                  }}
                >
                  Kaydet
                </Button>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      )}
    </Container>
  );
}

const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
