import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buttonfieldStyle,
  colorPalette,
  gridfieldStyle,
  textfieldStyle,
  theme,
} from "../themeGlobal";
import usePasswordStore from "../Requests/requestPassword";
import { useNavigate } from "react-router-dom";
import Logo from "../LandingPage/images/Logo.png";

export function PasswordVerification() {
  const { Password, data, error } = usePasswordStore();
  const [timeRemaining, setTimeRemaining] = useState(300); // 300 seconds = 5 minutes
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  const handleEmail = async (email, resetCode, password) => {
    await Password(email, resetCode, password);
  };

  useEffect(() => {
    if (data === true) navigate("/");
  }, [data]);

  // console.log(data, isLoading, error);
  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("password") === data.get("password2")) {
      handleEmail(
        localStorage.getItem("email"),
        data.get("kod"),
        data.get("password")
      );
    } else setPasswordError(true);
  }

  useEffect(() => {
    let interval;
    if (timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000); // Update the timer every 1 second (1000 milliseconds)
    } else {
      clearInterval(interval);
      navigate("/");
    }

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining]);

  // Convert the remaining time to minutes and seconds
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <ThemeProvider theme={theme}>
      <Container
        fixed
        sx={{ bgcolor: colorPalette.primary, minHeight: "800px" }}
      >
        {error && <Alert severity="error">Hatali Giris!</Alert>}
        <Box
          component="form"
          onSubmit={submitHandler}
          sx={{
            bgcolor: colorPalette.primary,
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            //   border: 1,
          }}
        >
          <Box>
            <Typography
              variant="h3"
              fontWeight={700}
              color={colorPalette.secondary}
              align="center"
              sx={{
                marginTop: "5%",
              }}
            >
              Parola Yenileme
            </Typography>
          </Box>
          <br />
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <TextField
              label="Kod"
              name="kod"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <TextField
              label="Parola"
              name="password"
              type="password"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <TextField
              error={passwordError}
              label="Parola"
              name="password2"
              type="password"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <Button type="submit" variant="contained" sx={buttonfieldStyle}>
              Gönder
            </Button>
            <br />
            <Grid container sx={gridfieldStyle}>
              <Grid item sm={12}>
                <Typography variant="body">
                  Lütfen Email'inize gelen kodu girin ve yeni parola belirleyin.
                </Typography>
              </Grid>

              <Grid item sm={12}>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </Grid>
            </Grid>
          </Box>
          <Box
            component="footer"
            sx={{
              // position: "fixed",
              // bottom: 0,
              paddingBottom: "5%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="Logo" style={{ width: "40%", margin: "5%" }} />

            <Typography>info@bulbeniqr.com</Typography>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              color={colorPalette.secondaryLight}
            >
              www.bulbeniqr.com
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
