import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buttonfieldStyle,
  colorPalette,
  gridfieldStyle,
  requirementBoxfieldStyle,
  textfieldStyle,
  theme,
} from "../themeGlobal";
import { Link, useNavigate } from "react-router-dom";
import useAuthStore from "../Requests/requestAuth";
import { useAuthentication } from "../store";
import Logo from "../LandingPage/images/Logo.png";

const isValidString = (inputString) =>
  /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(inputString);

export function SignUp({ dialog }) {
  const setIsLoggedIn = useAuthentication((state) => state.setIsLoggedIn);
  const { signUp, error, user, accessToken } = useAuthStore();
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (code, email, password) => {
    await signUp(code, email, password);
  };

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("email", user.email);
      localStorage.setItem("userId", user._id);
      setIsLoggedIn(true);
      navigate(`/panel/${user._id}`);
    }
  }, [accessToken]);

  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (password === data.get("password2") && isValidString(password)) {
      handleSignUp(data.get("code"), data.get("email"), password);
    } else setPasswordError(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        fixed
        sx={{
          minHeight: !dialog && "800px",
        }}
      >
        {error == 455 ? (
          <Alert severity="error">Kod Kullanımda!</Alert>
        ) : error == 456 ? (
          <Alert severity="error">Email Kullanımda!</Alert>
        ) : error == "Sign-up failed" ? (
          <Alert severity="error">Hatalı Kod!</Alert>
        ) : (
          ""
        )}
        <Box
          component="form"
          onSubmit={submitHandler}
          sx={{
            bgcolor: dialog ? "white" : colorPalette.primary,
            height: dialog ? "" : "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            // border: 1,
          }}
        >
          <Box>
            <Typography
              variant="h3"
              fontWeight={700}
              color={colorPalette.secondary}
              align="center"
              sx={{
                marginTop: "15%",
                marginBottom: "10%",
              }}
            >
              Üye ol
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              // border: 1,
            }}
          >
            <TextField
              label="Kod"
              name="code"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <TextField
              label="Parola"
              name="password"
              type="password"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <TextField
              error={passwordError}
              label="Parola"
              name="password2"
              type="password"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            {!isValidString(password) && (
              <Box sx={requirementBoxfieldStyle}>
                <Typography variant="body1">
                  Şifrenizin uzunluğu minimum 6 karakter olmalı, en az 1 adet
                  harf ve en az 1 adet rakam bulundurmalıdır.
                </Typography>
                <br />
              </Box>
            )}
            <Box sx={requirementBoxfieldStyle}>
              <Typography variant="body1">
                Okunmayan kodlar için{" "}
                {
                  <Link to="/check-code" color="primary">
                    tıklayın.
                  </Link>
                }
              </Typography>
            </Box>
            <br />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={buttonfieldStyle}
            >
              Giriş
            </Button>
            <br />
            <Grid
              container
              spacing={2}
              sx={{ ...gridfieldStyle, mb: dialog ? "20%" : "" }}
            >
              <Grid item xs={12}>
                <div>
                  Üye olarak{" "}
                  <Link to="/aydinlatma-metni" color="primary">
                    Aydınlatma Metni
                  </Link>
                  {", "}
                  <Link to="/gizlilik-politikasi" color="primary">
                    Gizlilik Sözleşmesi
                  </Link>
                  {" ve "}
                  <Link to="/kullanim-kosullari" color="primary">
                    Kullanım Koşulları
                  </Link>
                  {"nı kabul etmiş olursunuz."}
                </div>
              </Grid>
              {!dialog && (
                <Grid item>
                  <Link to="/sign-in" variant="body2">
                    {"Üye misin? Giriş yap"}
                  </Link>
                </Grid>
              )}
            </Grid>
          </Box>
          {dialog ? (
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "40%", marginBottom: "5%" }}
            />
          ) : (
            <Box
              component="footer"
              sx={{
                // border: 1,
                // mt: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                minHeight: "150px",
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: "40%" }} />

              <div>
                <Typography>info@bulbeniqr.com</Typography>
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  color={colorPalette.secondaryLight}
                >
                  www.bulbeniqr.com
                </Typography>
              </div>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
