import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  IconButton,
  Drawer,
  Dialog,
} from "@mui/material";
import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "../styles/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colorPalette } from "../../themeGlobal";
import { SignIn } from "../../Pages/SignIn";
import { SignUp } from "../../Pages/SignUp";
import Logo from "../images/Logo.png";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const classes = useStyles();
  const links = [
    {
      id: 1,
      route: "Satın Al",
      url: "https://www.otoaksesuar.com/bul-beni-qr-arac-cam-etiketi-karekod-numarator",
    },
    { id: 2, route: "Giriş Yap", url: "#" },
    { id: 3, route: "Üye Ol", url: "#" },
  ];

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((link) => (
          <ListItem button key={link.id}>
            {link.id === 1 ? (
              <Link
                href={link.url}
                target="_blank"
                underline="none"
                key={link.id}
              >
                <Typography color={colorPalette.secondary}>
                  {link.route}
                </Typography>
              </Link>
            ) : (
              <Typography
                key={link.id}
                style={{ cursor: "pointer" }}
                color={colorPalette.secondary}
                onClick={() => handleClick(link.id)}
              >
                {link.route}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const [open, setOpen] = React.useState(false);
  const [itemId, setItemId] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleClick(e) {
    setItemId(e);
    handleClickOpen();
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ marginBottom: "70px" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "30px" } }}
      >
        {itemId === 2 ? <SignIn dialog={true} /> : <SignUp dialog={true} />}
      </Dialog>
      <ElevationScroll {...props}>
        <AppBar sx={{ backgroundColor: "white" }}>
          <Toolbar className={classes.toolBar}>
            <Link href="/" underline="none" className={classes.logoImageStyle}>
              <img src={Logo} alt="Logo" width="100%" />
            </Link>

            {matches ? (
              <Box>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="menu"
                  onClick={toggleDrawer("right", true)}
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>

                <Drawer
                  anchor="right"
                  open={state["right"]}
                  onClose={toggleDrawer("right", false)}
                >
                  {list("right")}
                </Drawer>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: "0.1",
                }}
              >
                {links.map((link) =>
                  link.id === 1 ? (
                    <Link
                      href={link.url}
                      underline="none"
                      key={link.id}
                      sx={{ margin: "10px" }}
                    >
                      <Typography className={classes.link}>
                        {link.route}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography
                      key={link.id}
                      sx={{ margin: "10px" }}
                      style={{ cursor: "pointer" }}
                      className={classes.link}
                      onClick={() => handleClick(link.id)}
                    >
                      {link.route}
                    </Typography>
                  )
                )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
};

export default Header;
