import React, { useState } from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import useStyles from "../styles/styles";
import { motion } from "framer-motion";
import { colorPalette } from "../../themeGlobal";

const Footer = () => {
  const classes = useStyles();

  const linkVariants = {
    hover: {
      x: 100, // Move 10 pixels on the x-axis
      color: "black", // Change color to a different one on hover
    },
    initial: {
      x: 0,
      color: colorPalette.secondary, // Initial color
    },
  };

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.footerContainer}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12}>
          Destek
        </Grid>
        <Grid item xs={12} md={4}>
          <motion.a
            href="/aydinlatma-metni"
            variants={linkVariants}
            whileHover="hover"
            initial="initial"
            style={{
              textDecoration: "none",
              transition: "color 0.3s ease", // Smooth transition for color change
            }}
          >
            {" "}
            Aydınlatma Metni
          </motion.a>
        </Grid>
        <Grid item xs={12} md={4}>
          <motion.a
            href="/gizlilik-politikasi"
            variants={linkVariants}
            whileHover="hover"
            initial="initial"
            style={{
              textDecoration: "none",
              transition: "color 0.3s ease", // Smooth transition for color change
            }}
          >
            {" "}
            Gizlilik Politikası
          </motion.a>
        </Grid>
        <Grid item xs={12} md={4}>
          <motion.a
            href="/kullanim-kosullari"
            variants={linkVariants}
            whileHover="hover"
            initial="initial"
            style={{
              textDecoration: "none",
              transition: "color 0.3s ease", // Smooth transition for color change
            }}
          >
            Kullanım Koşulları
          </motion.a>
        </Grid>
      </Grid>
      <Typography className={classes.footerDate}>By OMSA 2023</Typography>
      <Typography className={classes.footerText}>info@bulbeniqr.com</Typography>
      <Typography className={classes.footerText}>
        Provided by{" "}
        <Link href="https://otoaksesuar.com" target="_blank" underline="none">
          otoaksesuar.com
        </Link>{" "}
      </Typography>
    </Box>
  );
};

export default Footer;
