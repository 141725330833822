// authStore.js
import { create } from "zustand";
import { ip } from "../themeGlobal";

const useAuthStore = create((set) => ({
  user: null,
  accessToken: null,
  isLoading: false,
  error: null,
  login: async (email, password) => {
    try {
      set({ isLoading: true, error: null });

      const response = await fetch(`${ip}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      // console.log(data);
      set({
        user: data.user,
        accessToken: data.accessToken,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
  signUp: async (code, email, password) => {
    try {
      set({ isLoading: true, error: null });

      const response = await fetch(`${ip}/users/sign-up`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, code }),
      });

      if (!response.ok) {
        if (response.status === 455) throw new Error(455);
        if (response.status === 456) throw new Error(456);
        throw new Error("Sign-up failed");
      }

      const data = await response.json();
      set({
        user: data.user,
        accessToken: data.accessToken,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
  logout: () => {
    set({ user: null, accessToken: null });
  },
}));

export default useAuthStore;
