import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { theme } from "../../../themeGlobal";
import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";

export function Kullanim() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Box height="100%" margin="40px">
          <br />
          <br />
          <strong>KULLANIM KOŞULLARI</strong>
          <br />
          <br />
          OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş.’nin sahibi
          olduğu www.bulbeniqr.com internet sitesinin (bundan sonra bulbeniQR
          olarak internet sitesi anılacaktır.) sayfalarından faydalanan
          kullanıcı, bu sayfada yer alan Kullanım Koşulları ve Sözleşme
          Şartlarını (“Kullanım Koşulları”) ve bulbeniqr.com internet sitesinde
          yer alan Gizlilik Sözleşmesi ve Şartlarını (“Gizlilik Politikası”) ve
          uygulamadaki kanun ve yönergeleri kabul eder.
          <br />
          <br />
          BulbeniQR tarafından sunulan münferit hizmet veya ürünlere ilişkin
          olarak düzenlenecek özel sözleşmeler, her ne kadar anlaşmazlık
          durumunda bu gibi özel sözleşmelerde yer alan hükümler geçerli olacak
          olsa da yukarıda anılan şart ve koşulları tamamlayıcı niteliktedir. Bu
          nedenle, BulbeniQR’da gezinmeye başlamadan önce aşağıda yer alan
          Kullanım Koşulları ve Sözleşme Şartlarını okumanızı tavsiye ederiz.
          BulbeniQR’da kullanılan marka, marka işareti, başlık, logo, grafik,
          tasarım ve metinler ile diğer malzemeler OMSA OTOMOTİV AKSESUARLARI
          YEDEK PARÇA SAN. VE TİC. A.Ş’ ye bağlı olarak yayın yapan BulbeniQR
          ’ın ya da OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş’ye
          ait başka bir şirketin mülkiyetindedir. BulbeniQR’da kullanılan metin
          ve grafik dosyaları telif hakları ve/veya marka kanunlarınca koruma
          altına alınmıştır ve/veya tasarım olarak tescillenmiştir veya bunlar
          OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş’nin başka
          herhangi bir şekilde koruma altına almış olduğu mülkleridir.
          <br />
          <br />
          OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş, mevcut
          Kullanım Koşulları ve Sözleşme Şartlarını önceden haber vermeksizin
          güncelleme yapma ve mevcut metni değiştirme hakkına sahiptir. Yapılan
          bu türden değişiklikler kullanıcıları bağlayıcı olacaktır.
          <br />
          <br />
          <ul>
            <li>
              <strong>Madde 1 – Sözleşmenin amacı,</strong>
              <br /> - Bir taraftan BulbeniQR ile diğer taraftan BulbeniQR’ı
              kullanacak olan veya kullanmakta olan BulbeniQR kullanıcısı (bu
              sözleşmede bundan böyle Kullanıcı olarak anılacaktır.) arasında,
              BulbeniQR’ın kullanımının tabi olduğu şartları ve hükümleri
              belirlemek ve bağlayıcılığını ve yürürlüğünü sağlamaktır.
            </li>
            <br />
            <li>
              <strong>
                Madde 2 – BulbeniQR’ın Kullanımı İle İlgili Hükümler,
              </strong>
              <br />
              - Kullanıcı, diğer kullanıcıları rahatsız edici bir biçimde veya
              tekrarlayan şekilde BulbeniQR üzerinden diğer kullanıcılarla
              hiçbir şekilde iletişime geçemez. Örneğin aynı etiket sahibine
              hergün bildirim yollayarak onu herhangi bir şekilde rahatsız
              edemez veya rahatsız olabileceği bir davranış içerisine giremez.
              <br />- BulbeniQR, herhangi bir nedenle(örneğin uygulama
              içerisindeki rahatsız edici davranışlar ve bununla sınırlı olmayan
              olumsuzluklar) gerek gördüğü halde fiziki olarak satılan ve/veya
              uygulama içerisinde etiket kullanımını tamamen kapatabilir. Bu
              durumdan dolayı herhangi bir şekilde hak talep edemez, ücret
              iadesi alamaz.
              <br />- Kullanıcı, BulbeniQR’ın açılmaya müsait dosyalarının
              virüslerden, wormlardan, truva atlarından, dialer programlarından
              veya bunlar gibi her türlü kötü ve zarar verme amaçlı kodlardan
              veya materyallerden arındırılmış olduğunu garanti etmediğini kabul
              eder. Bu tip kötü ve zarar verme amaçlı kodların veya
              materyallerin önlenmesi, veri giriş ve çıkışlarının doğruluğu veya
              herhangi bir kayıp verinin geri kazanılması için gereken tüm
              yazılım ve donanım ihtiyaçlarını karşılamak, bakımını ve
              güncellemelerini yapmak tamamen Kullanıcı’nın sorumluluğundadır.
              Bu tür kötü amaçlı kodlardan veya üçüncü şahısların uğrayabileceği
              hiçbir zarardan BulbeniQR sorumlu değildir. <br />- Kullanıcı,
              BulbeniQR kullanımından doğan tüm sorumluluğu ve riski
              üstlendiğini kabul eder. BulbeniQR, sunulan tüm içeriği “OLDUKLARI
              GİBİ” sağlamaktadır ve BulbeniQR hakkında veya BulbeniQR yoluyla
              sağlanan hiçbir servis, bilgi veya site içeriğine dahil herhangi
              bir unsurun herhangi bir şekilde kullanımı hakkında açık veya
              dolaylı garantiler sağlamamaktadır. BulbeniQR yoluyla sağlanan tüm
              düşünce, tavsiye, hizmet veya diğer bilgi ve materyalin doğruluk,
              tamlık ve kullanılırlığını değerlendirmek yalnızca Kullanıcı’nın
              sorumluluğundadır. <br />- Kullanıcı BulbeniQR kullanımı ile
              bağlantılı olarak şunları yapmayacağını kabul etmektedir; <br />-
              Yürürlükteki hiçbir Türkiye Cumhuriyeti yasasını, uluslar arası
              sözleşmeyi veya diğer herhangi bir devletin yasalarını herhangi
              bir şekilde ihlal etmeyeceğini. <br />- Türkiye Cumhuriyeti
              yasalarına, Türkiye Cumhuriyeti’nin taraf olduğu uluslar arası
              sözleşmelere, diğer uluslar arası sözleşmelere ve diğer ülke
              yasalarına aykırı, zararlı, tehdit, hakaret ve sövme içeren,
              suistimal veya taciz edici, haksız fiil veya iftira niteliğinde,
              kaba, müstehcen, kötüleyici veya başka birinin gizlilik haklarını
              ihlal edebilecek şekilde veya diğer herhangi bir biçimde
              kullanıcının hukuki ve cezai sorumluluğunu gerektiren hiçbir
              mesaj, bilgi, veri, metin, yazılım veya diğer herhangi bir tür
              materyali iletmeyeceğini. <br />- BulbeniQR’ın sahibi/sahipleri,
              görevlileri veya herhangi bir şekilde görev almasını da kapsayan
              ancak bununla sınırlı olmayarak, herhangi bir kişi veya işletmenin
              temsilcisiymiş gibi 3. şahıslar nezdinde temsil etmeyeceğini veya
              yanıltıcı ve halkın kafasında iltibas yaratabilecek biçimde bu
              kişi ve işletmeler ile ilgisi olduğunu ifade etmeyeceğini. <br />-
              BulbeniQR içeriğine BulbeniQR görevlilerinin veya diğer gerçek
              veya tüzel kişilerin ilettiği metin, resim, grafik, tescilli
              markalar, ticari unvanlar ve isimler gibi ve bunlarla sınırlı
              olmayarak BulbeniQR içeriğine dahil edilen herhangi bir materyali
              izinsiz kullanmayacağını. <br />- Virüsler, truva atları, casus
              programlar ve dialer programları gibi kötü amaçlı kodları ve
              materyalleri yerleştirmeyeceğini veya iletmeyeceğini. <br />-
              BulbeniQR’ın iletişim özelliklerini, BulbeniQR kaynaklarını
              herhangi bir yöntemle diğer kullanıcıların elde edebilmesini
              kısıtlayacak veya yok edecek biçimde kullanmayacağını. <br />-
              Hiçbir talep edilmeyen reklam, promosyonal materyal, junk mail
              veya spam, zincir mektuplar, ticari fikir ve duyurular veya başka
              herhangi bir biçimde ticari amaçlı metin, resim, ses, grafik ve
              diğer her türlü materyali yerleştirmeyeceğini veya iletmeyeceğini.{" "}
              <br />- Çerçeveleme (framing) veya benzer seyir teknolojilerini
              kullanarak BulbeniQR’ı kendi amaçları doğrultusunda
              yönlendirmeyeceğini veya başka bir biçimde görüntülemeyeceğini.{" "}
              <br />- BulbeniQR, BulbeniQR’ın kullanımından kaynaklanan hiçbir
              hukuk ve ceza davasının muhatabı değildir. BulbeniQR,
              kullanıcılarının veya üçüncü şahısların fiillerinden doğabilecek
              zarar, ziyan, masraf ve diğer her türlü talepten doğrudan veya
              dolaylı olarak sorumlu değildir.
            </li>
            <br />
            <li>
              <strong>Madde 3 – BulbeniQR’ın Sorumluluğunun Sınırları,</strong>
              <br />- BulbeniQR hiçbir durumda şunlardan sorumlu olmayacaktır;
              <br />- BulbeniQR, BulbeniQR’ın kullanılmasından veya
              kullanılmamasından, BulbeniQR üzerinde sağlanan veya indirilen
              (download edilen) herhangi bir işlem, servis, bilgi, veri ve diğer
              her türlü materyalden veya bu tip bilgi veya servisin
              gecikmesinden doğan hiçbir tesadüfi, risk nedeniyle oluşan veya
              dolaylı zararlardan (kâr kaybından, işin yarıda kesilmesinden,
              programların veya bilginlerin kaybından doğan zararlar da dahil
              fakat bunlarla sınırlı olmamak kaydıyla) BulbeniQR veya onun
              yetkili temsilcileri haberdar edilmiş olsalar dahi sorumlu
              olmayacaktır.
              <br />- BulbeniQR, BulbeniQR içindeki hatalar, atlamalar veya
              diğer kusurlardan ve/veya BulbeniQR yoluyla indirilen (download
              edilen) materyal veya bilgilerden kaynaklanan herhangi bir
              talepten sorumlu olmayacaktır.
              <br />- Kullanıcı tüm telefon, bilgisayar donanımı, internet ve
              bilgisayar güvenliği ve BulbeniQR’a erişim ve BulbeniQR kullanımı
              için gerekli her türlü ekipmanın iyi çalışmasından, bakımından,
              güncellenmesinden ve bu ekipmanların her türlü
              ücretlendirilmesinden, vergilerinden ve her türlü parasal
              yükümlülüklerinden kendisi sorumludur. Kullanıcı’nın bu
              yükümlülüklere uymamasından dolayı uğrayabileceği her türlü
              zarardan, her türlü dava ve talepten tamamen Kullanıcı sorumludur.
              <br />- Kullanıcı, BulbeniQR’a erişimi ve BulbeniQR kullanımı
              nedeniyle bu sözleşmenin herhangi bir maddesini ihlal ederse bu
              ihlalden kaynaklanan dava harç ve masrafları ile avukatlık
              ücretleri de dahil olmak üzere, tüm kayıp, harcama, zarar ve
              masraflardan sorumludur. BulbeniQR hiçbir şekilde bu sayılanlardan
              sorumlu değildir.
              <br />- BulbeniQR, bir yargı merciinin kararı veya bunu gerekli
              görmesi halinde, kullanıcının hesabının yasadışı eylemler için
              kullanıldığını tespit etmesi veya bunu haklı gösterecek inandırıcı
              kanıtların varlığı halinde, mevcut veya geçmiş kullanıcı veya
              BulbeniQR bilgilerini açıklama hakkını saklı tutar.
              <br />- BulbeniQR’ın kullanımı ve tazminatları ile ilgili
              hükümleri, BulbeniQR çalışanları, temsilcileri, lisansörleri,
              sponsorları da kendi nam ve hesaplarına kullanıcıya karşı ileri
              sürebilirler.
            </li>
            <br />
            <li>
              <strong>Madde 4 – UluslarArası Kullanım İçin Özel Uyarı </strong>
              <br />- İnternetin küresel doğasının farkında olarak, Kullanıcı,
              internet, veri, e-posta veya gizlilikle ilgili kurallar da dahil
              fakat bunlarla sınırlı olmaksızın tüm yerel yasalara ve diğer
              mevzuata uymayı kabul eder. Kullanıcı, özellikle de Türkiye’deki
              veya kullanıcının ikamet ettiği ülkedeki yasalar ve diğer mevzuata
              uymayı kabul eder.
            </li>
            <br />
            <li>
              <strong>
                Madde 5 – Üçüncü Kişilerle Kullanıcı Arasındaki İlişkiler
              </strong>
              <br />- Kullanıcı, ile BulbeniQR üzerinde bulunan veya BulbeniQR
              yoluyla kullanıcının ilişki kurduğu herhangi bir üçüncü kişi ile
              olan mal ve hizmetlerin sunumu ve bunların ödenmesi ve teslimi ve
              diğer herhangi bir hüküm, şart, garanti ve temsil ile ilgili
              olarak Kullanıcı’nın katılımı, yazışması veya iş ilişkileri ve
              diğer her türlü ilişkisi yalnızca Kullanıcı ile üçüncü kişi
              arasındadır. Kullanıcı, böyle bir ilişkinin sonucu olarak uğranan
              herhangi bir kayıp, zarar veya diğer herhangi bir çeşit talepten
              ve meseleden BulbeniQR’nın sorumlu olmadığını kabul eder.
            </li>
            <br />
            <li>
              <strong>Madde 6 – Linkler</strong>
              <br />- Kullanıcı, BulbeniQR üzerindeki linklerin kaynakları
              üzerinde BulbeniQR’ın hiçbir kontrolü olmadığı için BulbeniQR’ın
              linklerin gösterdiği web sitelerinin veya kaynakların
              ulaşılabilirliğinden sorumlu olmadığını ve bu web siteleri ve
              kaynaklar üzerinde bulunan veya bunlardan elde edilebilen hiçbir
              içerik, reklam, ürün veya diğer materyalden sorumlu olmadığını
              kabul eder. Kullanıcı ayrıca BulbeniQR’ın böyle herhangi bir
              içerik, mal veya hizmete güvenerek veya bunlar tarafından veya
              bunların kullanımı ile bağlantılı olarak neden olunan veya neden
              olunduğu iddia olunan herhangi bir zarar veya kayıptan doğrudan
              veya dolaylı olarak sorunlu olmadığını kabul eder.
            </li>
            <br />
            <li>
              <strong>
                Madde 7 – İhtilafların Çözümlenmesinde Uygulanacak Hukuk ve
                Yetki
              </strong>
              <br />- Bu sözleşmeden doğan taraflar arasındaki uyuşmazlıkların
              çözümünde sözleşmenin bütününün veya kısımlarının uygulanmasında
              ve yorumlanmasında Türkiye Cumhuriyeti yasaları ve mevzuatı
              geçerli olacaktır. Taraflar bunu kayıtsız ve şartsız kabul
              ederler.
              <br />- Bu sözleşmeden doğan taraflar arasındaki uyuşmazlıkların
              çözümünde Bursa mahkemeleri ve icra daireleri yetkili olacaktır.
            </li>
            <br />
            <li>
              <strong>Madde 8 – Muhtelif Hükümler</strong>
              <br />- BulbeniQR’ın bu sözleşmenin herhangi bir maddesinin
              uygulanmasını talep etmesindeki eksikliği herhangi bir hüküm veya
              haktan vazgeçtiği anlamına gelmez.
              <br />- Ne taraflar arasındaki davranış biçimi ne de ticari
              uygulamalar ve örf ve adetler bu sözleşmenin herhangi bir hükmünün
              değiştirilmesi için BulbeniQR’ın sözleşmeyi değiştirme hakkı saklı
              kalmak kaydıyla herhangi bir rol oynamayacaktır.
            </li>
          </ul>
          <br />
          <strong>Yayınlanma Tarihi: 20.11.2023</strong>
          <br /> BulbeniQR gerek gördüğü takdirde Kullanım Koşulları’na ait
          maddelerde değişikliğe gidebilir.
          <br />
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}
