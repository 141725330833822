import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { theme, boxfieldStyle } from "../../../themeGlobal";
import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";

export function Gizlilik() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Box sx={boxfieldStyle} margin="40px">
          <br />
          <br />
          <strong>GİZLİLİK POLİTİKASI</strong>
          <br />
          <br />
          OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş. (BulbeniQR)
          <br />
          <br />
          Ticari İletişime ve Kişisel Verilerin İşlenmesine ilişkin
          <br />
          <br />
          Açık Rıza/İzin Beyanı
          <br />
          <br />
          6698 sayılı Kişisel Verilerin Korunması Kanunu ("Kanun") kapsamında
          daha önce benimle paylaşılan, internet siteleri (www.bulbeniqr.com
          “Site”) üzerinden her zaman ulaşabileceğim ve https://www.
          bulbeniqr.com/aydınlatma-metni linkinde yer alan aydınlatma metninde
          detaylı olarak açıklandığı şekilde kişisel verilerimin işlenmesine ve
          tarafıma ticari iletişim yapılmasına, aşağıdaki rıza/izin beyanı ile
          onay veriyorum.
          <br />
          <br />
          Aydınlatma metninde detaylı olarak açıklanan pazarlama, iletişim ve
          kimlik verilerimin OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC.
          A.Ş. (BulbeniQR) ve iştirakleri tarafından; genel veya bana özel
          kişiselleştirilmiş kampanyalar, avantajlar, promosyonlar, reklamlar,
          bilgilendirmeler, pazarlama faaliyetleri kapsamında Site veya diğer
          kanallar üzerinden kısa mesaj (SMS), anlık bildirim, e-mail, arama,
          reklam sağlanması ile ticari elektronik ileti ve diğer ileti
          gönderimleri ile iletişimlerin yapılabilmesine ve bu amaçla kişisel
          verilerimin işlenmesine, ticari iletişim izni verdiğim bu metinde
          ticari unvanları yer alan şirketlerle paylaşılmasına onay/izin
          veriyorum.
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}
