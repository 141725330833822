import { makeStyles } from "@mui/styles";
import { colorPalette } from "../../themeGlobal";
//#2f4962 / #587c9a
const styles = (theme) => {
  return {
    toolBar: {
      height: "10vh",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      backgroundColor: "white",
      // background: `linear-gradient(to bottom, ${colorPalette.secondary}, ${colorPalette.secondaryLight})`,
    },
    logo: {
      color: colorPalette.primary,
      cursor: "pointer",
    },
    link: {
      color: colorPalette.secondary,
    },
    menuIcon: {
      color: colorPalette.secondary,
    },
    formContainer: {
      flexGrow: 1,
      padding: "10px",
      maxWidth: "700px",
      margin: "30px auto",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%",
      },
    },
    form: {
      marginTop: "30px",
    },
    formHeading: {
      textAlign: "center",
    },
    heroBox: {
      width: "100%",
      display: "flex",
      minHeight: "600px",
      alignItems: "center",
      justifyContent: "center",
    },
    gridContainer: {
      display: "flex",
      alignItems: "center",
      maxWidth: "1300px",
      padding: "50px",
    },
    aboutUsContainer: {
      width: "100%",
      display: "flex",
      minHeight: "400px",
      alignItems: "center",
      justifyContent: "center",
      padding: "30px 0px 50px 0px",
    },
    aboutUsSubtitle: {
      opacity: "0.7",
      paddingBottom: "30px",
      fontSize: "18px",
    },
    title: {
      paddingBottom: "15px",
      color: colorPalette.secondary,
      // fontFamily: "Poppins",
    },
    subtitle: {
      // opacity: "0.6",
      paddingBottom: "20px",
    },
    largeImage: {
      width: "100%",
    },
    aboutUsImage: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    sectionGridBox: {
      flexGrow: 1,
      minHeight: "400px",
    },
    sectionGridContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minHeight: "500px",
    },
    sectionGridItem: {
      backgroundColor: colorPalette.secondaryLight,
      textAlign: "center",
      padding: "30px",
      width: "200px",
      borderRadius: "10px",
      margin: "10px !important",
    },
    inputField: {
      marginBottom: "20px !important",
    },
    textArea: {
      width: "100%",
      marginBottom: "20px",
      fontSize: "16px",
      padding: "10px",
    },
    footerContainer: {
      display: "flex",
      alignItems: "center",
      miHeight: "10vh",
      padding: "20px",
      justifyContent: "center",
      backgroundColor: "white",
      flexDirection: "column",
    },
    footerText: {
      paddingBottom: "10px",
    },
    footerDate: {
      opacity: "0.4",
    },
    avatar: {
      marginRight: "10px",
    },
    logoImageStyle: {
      [theme.breakpoints.down("sm")]: {
        width: "40%",
      },
      [theme.breakpoints.down("md")]: {
        width: "40%",
      },
      width: "20%",
    },
  };
};

const useStyles = makeStyles(styles);
export default useStyles;
