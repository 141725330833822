import { createTheme } from "@mui/material";

export const ip = "https://dev.bulbeniqr.com";
// export const ip = "http://localhost:4000";

export const colorPalette = {
  primary: "#f4feff",
  secondary: "#2f4962",
  secondaryLight: "#587c9a",
  icon: "#FFFBEB",
  text: "white",
};

export const theme = createTheme({
  typography: {
    // font-family: 'Open Sans', sans-serif;
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    // fontFamily: "sans-serif",
    h1: {
      color: colorPalette.text,
      // fontSize: "5vw",
    },
    h2: {
      color: colorPalette.text,
    },
    h3: {
      fontFamily: "Arial",
    },
    plaka: {
      color: "black",
      width: "100%",
      fontFamily: "Helvetica Neue",
    },
    model: {
      color: "white",
      fontFamily: "Helvetica Neue",
      // margin: "5px",
    },
    body3: {
      color: colorPalette.text,
    },
    subtitle1: {
      color: colorPalette.text,
      margin: "5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  breakpoints: {
    xs: 0, // Extra small screens
    sm: 600, // Small screens
    md: 960, // Medium screens
    lg: 1280, // Large screens
    xl: 1920, // Extra large screens
  },
});

export const radioButton = {
  color: colorPalette.secondary,
};

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: "2.5rem",
  fontWeight: 800,
  [theme.breakpoints.down("xl")]: {
    fontSize: "2.5rem",
    fontWeight: 800,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.25rem",
    fontWeight: 800,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
    fontWeight: 800,
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "2rem",
  fontWeight: 500,
  [theme.breakpoints.down("xl")]: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.75rem",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
};

theme.typography.plaka = {
  ...theme.typography.plaka,
  fontSize: "1.5rem",
  fontWeight: 600,
  [theme.breakpoints.down("xl")]: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
};

theme.typography.model = {
  ...theme.typography.model,
  fontSize: "1.25rem",
  fontWeight: 500,
  [theme.breakpoints.down("xl")]: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    fontWeight: 500,
  },
};

export const denemeKutu = {
  bgcolor: colorPalette.secondary,
  height: "100px",
  width: "100px",

  // [theme.breakpoints.down("xs")]: {
  //   width: "75%",
  // },

  [theme.breakpoints.down("xl")]: {
    width: "45%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "55%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    alignItems: "end",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    width: "75%",
  },
};

export const headerBoxStyles = {
  bgcolor: colorPalette.secondary,
  height: "10%",
  borderBottomLeftRadius: "20px",
  borderBottomRightRadius: "20px",
  display: "flex",
  justifyContent: "center",
};

export const headerCardPlateStyles = {
  margin: "5px",
  height: "35%",
  width: "50%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
  border: 1,
  borderWidth: "3px",
  backgroundColor: "white",
  [theme.breakpoints.down("xl")]: {
    width: "50%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "65%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
};

export const headerBoxPlateStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  width: "15%",
  height: "100%",
  backgroundColor: "blue",
};

export const personalBoxStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "20px",
  // [theme.breakpoints.down("sm")]: {
  //   height: "250px",
  // },
  // [theme.breakpoints.down("md")]: {
  //   height: "250px",
  // },
};

export const personalCardStyles = {
  height: "100%",
  width: "75%",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // borderRadius: "20px",

  backgroundColor: colorPalette.secondary,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
  [theme.breakpoints.down("xs")]: {
    width: "75%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "75%",
  },
  // [theme.breakpoints.down("md")]: {
  //   width: "65%",
  // },
  // [theme.breakpoints.down("lg")]: {
  //   width: "55%",
  // },
  // [theme.breakpoints.down("xl")]: {
  //   width: "45%",
  // },
};

export const avatarBoxStyles = {
  width: "100px",
  height: "100px",
  marginBottom: "10px",
  // [theme.breakpoints.down("md")]: {
  //   width: "120px",
  //   height: "120px",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "100px",
  //   height: "100px",
  // },
};

export const contactBoxStyles = {
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const contactBoxContentStyles = {
  borderRadius: "25px",
  width: "80%",
  backgroundColor: colorPalette.secondary,
};

export const socialBoxContentStyles = {
  // borderRadius: "20px",
  width: "80%",
  height: "80px",
  // backgroundColor: colorPalette.secondary,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};

export const gridButtonStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "80px",
  width: "100%",
  borderRadius: "25px",
};

export const gridItemStyles = {
  color: colorPalette.icon,
};

export const qrButtonStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "80px",
  width: "80px",
  borderRadius: "40px",
  border: 1,
  borderWidth: "5px",
  borderColor: colorPalette.secondary,
};

export const qrDialogBoxStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "80vh",
  width: "60vh",
  border: 1,
  borderWidth: "5px",
  borderColor: colorPalette.secondary,
  backgroundColor: colorPalette.primary,
  [theme.breakpoints.down("sm")]: {
    height: "65vh",
    width: "40vh",
  },
};

export const qrDialogQRStyles = {
  height: "300px",
  width: "300px",
  backgroundColor: colorPalette.primary,
  [theme.breakpoints.down("sm")]: {
    height: "200px",
    width: "200px",
  },
};

export const messageBoxStyles = {
  height: "175px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    height: "150px",
  },
};

export const messageBoxContentStyles = {
  width: "70%",
  height: "100px",
  borderRadius: "20px",
  backgroundColor: colorPalette.secondary,
  opacity: "0.5",
  overflow: "auto",
};

export const textfieldStyle = {
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  width: "500px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: colorPalette.secondaryLight,
      borderRadius: "15px",
      // borderBottomWidth: "5px",
    },
    "&:hover fieldset": {
      borderColor: colorPalette.secondary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colorPalette.secondary,
    },
  },
};

export const buttonfieldStyle = {
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  width: "500px",
  backgroundColor: "#21469c",
  "&:hover": {
    backgroundColor: colorPalette.secondary,
  },
};

export const gridfieldStyle = {
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  width: "500px",
  // mb: "20%",
};

export const requirementBoxfieldStyle = {
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  width: "500px",
};

export const boxfieldStyle = {
  [theme.breakpoints.down("sm")]: {
    height: "100%",
  },
  height: "100vh",
};
