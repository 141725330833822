// store.js
import { create } from "zustand";
import { ip } from "../themeGlobal";

const useUserDataStore = create((set) => ({
  userData: null,
  isLoading: false,
  error: null,
  fetchUserData: async (userId) => {
    try {
      set({ isLoading: true });

      // Make the API call to retrieve user data
      const response = await fetch(`${ip}/users/${userId}`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch user data: ${response.status}`);
      }

      const data = await response.json();

      set({ userData: data, isLoading: false });
    } catch (error) {
      console.error("Error fetching user data:", error);
      set({ error, isLoading: false });
    }
  },
  updateUser: async (userId, updatedUserData) => {
    try {
      set({ isLoading: true });

      // Make the API call to update user data
      const response = await fetch(`${ip}/users/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Replace with your actual access token
        },
        body: JSON.stringify(updatedUserData),
      });

      if (!response.ok) {
        throw new Error(`Failed to update user data: ${response.status}`);
      }

      const data = await response.json();

      set({ userData: data, isLoading: false });
    } catch (error) {
      console.error("Error updating user data:", error);
      set({ error, isLoading: false });
    }
  },
  checkCode: async (code) => {
    try {
      set({ isLoading: true, error: null });

      const response = await fetch(`${ip}/users/check-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error("Check Code failed");
      }

      const data = await response.json();

      set({
        userData: data,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
}));

export default useUserDataStore;
