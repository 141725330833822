import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  buttonfieldStyle,
  colorPalette,
  gridfieldStyle,
  textfieldStyle,
  theme,
} from "../themeGlobal";
import { Link, useNavigate } from "react-router-dom";
import { useAuthentication } from "../store";
import useAuthStore from "../Requests/requestAuth";
import Logo from "../LandingPage/images/Logo.png";

export function SignIn({ dialog }) {
  const setIsLoggedIn = useAuthentication((state) => state.setIsLoggedIn);
  const { login, error, user, accessToken } = useAuthStore();
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    await login(email, password);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setIsLoggedIn(true);
      navigate(`/${localStorage.getItem("userId")}`);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("email", user.email);
      localStorage.setItem("userId", user.id);
      setIsLoggedIn(true);
      navigate(`/${user.id}`);
    }
  }, [accessToken]);

  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    handleLogin(data.get("email"), data.get("password"));
  }

  return (
    <ThemeProvider theme={theme}>
      <Container fixed>
        {error && <Alert severity="error">{"Hatali Giris!"}</Alert>}
        <Box
          component="form"
          onSubmit={submitHandler}
          sx={{
            bgcolor: dialog ? "white" : colorPalette.primary,
            height: dialog ? "" : "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            // border: 1,
          }}
        >
          <Box>
            <Typography
              variant="h3"
              fontWeight={700}
              color={colorPalette.secondary}
              align="center"
              sx={{
                marginTop: "15%",
              }}
            >
              Giriş
            </Typography>
          </Box>
          <br />
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              required
              sx={textfieldStyle}
            />
            <br />
            <TextField
              label="Parola"
              name="password"
              type="password"
              variant="outlined"
              required
              sx={textfieldStyle}
            />
            <br />
            <Button
              type="submit"
              variant="contained"
              // fullWidth
              sx={buttonfieldStyle}
            >
              Giriş
            </Button>
            <br />
            <Grid container sx={{ ...gridfieldStyle, mb: dialog ? "20%" : "" }}>
              <Grid item xs>
                <Link to="/email" variant="body2">
                  Parolamı Unuttum
                </Link>
              </Grid>
              <Grid item>
                <Link to="/sign-up" variant="body2">
                  {"Üye ol"}
                </Link>
              </Grid>
            </Grid>
          </Box>
          {dialog ? (
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "40%", marginBottom: "5%" }}
            />
          ) : (
            <Box
              component="footer"
              sx={{
                // border: 1,
                // mt: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                minHeight: "150px",
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: "40%" }} />

              <div>
                <Typography>info@bulbeniqr.com</Typography>
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  color={colorPalette.secondaryLight}
                >
                  www.bulbeniqr.com
                </Typography>
              </div>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
