import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  buttonfieldStyle,
  colorPalette,
  textfieldStyle,
  theme,
} from "../themeGlobal";
import usePasswordStore from "../Requests/requestPassword";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Components/Loading";
import Logo from "../LandingPage/images/Logo.png";

export function EmailVerification() {
  const { Email, data, isLoading, error } = usePasswordStore();
  const navigate = useNavigate();

  const handleEmail = async (email) => {
    await Email(email);
  };
  // console.log(data, isLoading, error);
  useEffect(() => {
    if (data?.message_id) {
      navigate("/password");
    }
  }, [data]);

  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    localStorage.setItem("email", data.get("email"));
    handleEmail(data.get("email"));
  }

  return (
    <ThemeProvider theme={theme}>
      <Container fixed sx={{ bgcolor: colorPalette.primary }}>
        {error && <Alert severity="error">Hatali Giris!</Alert>}
        {isLoading ? (
          <Loading />
        ) : (
          <Box
            component="form"
            onSubmit={submitHandler}
            sx={{
              bgcolor: colorPalette.primary,
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              //   border: 1,
            }}
          >
            <Typography
              variant="h3"
              fontWeight={700}
              align="center"
              color={colorPalette.secondary}
              sx={{
                marginTop: "5%",
                position: "fixed",
                top: 0,
              }}
            >
              Email Doğrulama
            </Typography>
            <br />
            <br />
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              required
              fullWidth
              sx={textfieldStyle}
            />
            <br />
            <Button type="submit" variant="contained" sx={buttonfieldStyle}>
              Gönder
            </Button>
            <Box
              component="footer"
              sx={{
                position: "fixed",
                bottom: 0,
                paddingBottom: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "40%", margin: "5%" }}
              />

              <Typography>info@bulbeniqr.com</Typography>
              <Typography
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                color={colorPalette.secondaryLight}
              >
                www.bulbeniqr.com
              </Typography>
            </Box>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
