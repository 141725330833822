// authStore.js
import { create } from "zustand";
import { ip } from "../themeGlobal";

const usePasswordStore = create((set) => ({
  data: null,
  isLoading: false,
  error: null,
  Email: async (email) => {
    try {
      set({ isLoading: true, data: null, error: null });

      const response = await fetch(`${ip}/users/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Email send failed");
      }

      const data = await response.json();
      // console.log("data", data);
      set({
        data,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
  Password: async (email, resetCode, password) => {
    try {
      set({ isLoading: true, data: null, error: null });

      const response = await fetch(`${ip}/users/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, resetCode, password }),
      });

      if (!response.ok) {
        throw new Error("Password reset failed");
      }

      const data = await response.json();
      // console.log("data", data);
      set({
        data,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
}));

export default usePasswordStore;
