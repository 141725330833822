import React from "react";
import Header from "../LandingPage/components/Header";
import Footer from "../LandingPage/components/Footer";
import {
  Box,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { theme } from "../themeGlobal";
import useUserDataStore from "../Requests/requestUser";

const CheckCode = () => {
  const { userData, isLoading, checkCode, error } = useUserDataStore();

  function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get("code"));
    if (data.get("code").length > 3) checkCode(`${data.get("code")}`);
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          onSubmit={submitHandler}
          component="form"
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "30px",
              }}
            >
              <Typography
                // fontFamily={"Roboto"}
                // fontSize={30}
                // fontWeight={"bold"}
                // color={"black"}
                align="center"
              >
                Okunmayan kodlar için aşağıya en az 4 ardışık harf girin.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField name="code" />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="submit" variant="contained">
                Check
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!error && (
                <>
                  <Typography
                    fontFamily={"Roboto"}
                    fontSize={30}
                    fontWeight={"bold"}
                    color={"black"}
                  >
                    {!error && userData?.user?.code}
                  </Typography>
                  &nbsp; &nbsp;
                  <Box
                    width="20px"
                    height="20px"
                    bgcolor={userData?.user?.isSigned ? "red" : "green"}
                    borderRadius={3}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default CheckCode;
