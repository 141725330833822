import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { theme } from "../../../themeGlobal";
import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";

export function Aydinlatma() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Box height="100%" margin="40px">
          <br />
          <br />
          <strong>AYDINLATMA METNİ</strong>
          <br />
          <br />
          OMSA OTOMOTİV AKSESUARLARI YEDEK PARÇA SAN. VE TİC. A.Ş. (Bundan sonra
          “BulbeniQR” olarak anılacaktır.) aktarılan kişisel verilerin korunması
          konusundaki temel bilgilere aşağıda yer vermiştir.
          <br />
          <br />
          BulbeniQR, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) md.
          10’dan doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla
          aşağıdaki açıklamaları; müşterilerimizin ve web-sitemizi kullanan,
          KVKK’nın md.3’deki tanım gereği sadece gerçek kişi olan 3. kişilerin
          dikkatine sunar.(BulbeniQR, işbu Kişisel Verilerin Korunması Hakkında
          Açıklama metnini yürürlükteki mevzuatta yapılabilecek değişiklikler
          çerçevesinde her zaman güncelleme hakkını saklı tutar.) 6698 sayılı
          Kanun uyarınca, veri sorumlusu sıfatını haiz BulbeniQR tarafından elde
          edilmiş/edilecek ya da paylaştığınız/paylaşacağınız şahsınıza ait her
          türlü bilginin (“kişisel veri”), aşağıda açıklanan çerçevede ve anılan
          Kanunda öngörülen şekillerde BulbeniQR tarafından işleneceğini ve
          bilgilerinizin otomatik olan ya da olmayan yollarla elde edilmesi,
          kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi,
          açıklanması, aktarılması, elde edilebilir hale getirilmesi de dâhil
          olmak üzere bilgileriniz üzerinde gerçekleştirilen her türlü işlemin
          “kişisel verilerin işlenmesi” anlamına geldiğini bilgilerinize
          sunarız. Ayrıca 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında
          Kanun da kişisel verilerin korunmasına ilişkin hüküm içermektedir.
          5237 Sayılı Türk Ceza Kanunu hükümleri yoluyla da kişisel verilerin
          korunması için bazı hallerde cezai yaptırımlar öngörülmüştür. Diğer
          yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli
          Sözleşmeler Yönetmeliği’nden doğan yükümlülüklerimizin ifası amacıyla
          verilerin toplanması ve kullanılması gerekmektedir.
          <br />
          <br />
          Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri
          <br />
          BulbeniQR, başta 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi
          Hk. Kanun, 4358 sayılı Vergi Kimlik Numarası Kullanımının
          Yaygınlaştırılması Hk. Kanun olmak üzere ilgili tüm
          ulusal/uluslararası mevzuat ve bunlara dayalı olarak
          ulusal/uluslararası yetkili otoritelerce yayımlanmış olan ikincil
          düzenlemeler ya da bunlarla ilgili olarak veya faaliyetleri ile ilgili
          olarak taraf olduğu sözleşmeler kapsamındaki yükümlülüklerinin (kimlik
          tespiti, bilgi saklama, raporlama, bilgilendirme vb.) ve
          taahhütlerinin yerine getirilmesi; BulbeniQR’dan aldığınız her türlü
          ürün ve hizmetlere ilişkin sözleşmelerin kurulması ve diğer tüm kayıt
          ve belgelerin (kağıt veya elektronik ortamda) düzenlenmesi, söz konusu
          ürün ve hizmetlerin sunulması ve de gereği gibi kesintisiz olarak
          sürdürülebilmesi gibi amaçlar ve hukuki sebeplerle kişisel verileriniz
          BulbeniQR tarafından işlenmektedir.
          <br />
          BulbeniQR, kişisel verileri hangi amaçlarla kullanıyor?
          <br />
          BulbeniQR, mevzuatın izin verdiği durumlarda ve ölçüde kişisel
          bilgilerinizi kaydedebilecek, saklayabilecek, güncelleyebilecek,
          üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek
          ve işleyebilecektir.
          <br />
          <br />
          <strong>Kişisel verileriniz şu amaçlarla kullanılmaktadır:</strong>
          <ul>
            <li>
              - Web sitesi üzerinden yapılan haberleşmelerin güvenliğini
              sağlamak,
            </li>
            <li>
              - Web sitesi üzerinden alışveriş yapanın/yaptıranın kimlik
              bilgilerini teyit etmek,
            </li>
            <li>- İletişim için adres ve diğer gerekli bilgileri kaydetmek,</li>
            <li>
              - Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında
              Kanun’un ilgili maddeleri tahtında akdettiğimiz sözleşmelerin
              koşulları, güncel durumu ve güncellemeler ile ilgili
              müşterilerimiz ile iletişime geçmek, gerekli bilgilendirmeleri
              yapabilmek,
            </li>
            <li>
              - Elektronik (internet/mobil vs.) veya kâğıt ortamında işleme
              dayanak olacak tüm kayıt ve belgeleri düzenlemek,
            </li>
            <li>
              - Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında
              Kanun’un ilgili maddeleri tahtında akdettiğimiz sözleşmeler
              uyarınca üstlenilen yükümlülükleri ifa etmek,
            </li>
            <li>
              - Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat
              gereği kamu görevlilerine bilgi verebilmek,
            </li>
            <li>
              - Müşterilerimize daha iyi bir hizmet ve alışveriş deneyimini
              sağlamak, “müşterilerimizin ilgi alanlarını dikkate alarak”
              müşterilerimizin ilgilenebileceği ürünler veya hizmetler hakkında
              müşterilerimize bilgi verebilmek, kampanyaları aktarmak,
            </li>
            <li>
              - Müşteri memnuniyetini artırmak, web sitesi ve/veya mobil
              uygulamalayı kullanan müşterilerimizi tanıyabilmek ve müşteri
              çevresi analizinde kullanabilmek, çeşitli pazarlama ve reklam
              faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı kuruluşlar
              aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler
              düzenlemek, bilgi paylaşımı yapmak
            </li>
            <li>
              - Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından
              müşterilerimize öneri veya hizmet/ürün sunabilmek, hizmetlerimizle
              ilgili müşterilerimizi bilgilendirebilmek,
            </li>
            <li>
              - Hizmetlerimiz ile ilgili müşteri şikâyet ve önerilerini
              değerlendirebilmek,
            </li>
            <li>
              - Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki
              mevzuattan doğan haklarımızı kullanabilmek,
            </li>
          </ul>
          <br />
          <br />
          İşbu BulbeniQR ile işlem yapan müşterilerimizin verdikleri veriler,
          müşterilerimizin rızaları ve mevzuat hükümleri uyarınca BulbeniQR
          tarafından işlenmektedir.
          <br />
          <br />
          İşbu Web sitesi ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve
          yasal yükümlülüğü çerçevesinde, işbu Kişisel Verilerin Korunması
          Hakkında Açıklama metninde belirlenen amaçlar ve kapsam dışında
          kullanılmamak kaydı ile gezinme bilgilerinizi toplayacak, işleyecek,
          üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır.
          BulbeniQR’dan kredi kartı ile alışveriş yapan müşterilerimiz, kişisel
          verilerini ödemeyle ilgili kurum/kuruluş ile paylaşmaya ve işlenmesine
          rıza göstermiş sayılır. İşbu BulbeniQR günlük dosyaları, boş gif
          dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri
          tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar. İşbu
          BulbeniQR, size özel tanıtım yapmak, promosyonlar ve pazarlama
          teklifleri sunmak, BulbeniQR içeriğini size göre iyileştirmek ve/veya
          tercihlerinizi belirlemek amacıyla; BulbeniQR üzerinde gezinme
          bilgilerinizi ve/veya site üzerindeki kullanım geçmişinizi
          izleyebilmektedir.
          <br />
          <br />
          İşbu BulbeniQR, çevrimiçi ve çevrimdışı olarak toplanan bilgiler gibi
          farklı yöntemlerle veya farklı zamanlarda BulbeniQR üzerinde sizden
          toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi
          başka kaynaklardan alınan bilgilerle birlikte kullanabilir.
          <br />
          <br />
          İşlenen kişisel verilerinizin yukarıda belirtilen amaçlarla
          aktarılabileceği kişi ya da kuruluşlar
          <br />
          <br />
          Kişisel verileriniz, yukarıda atıf yapılan tüm ulusal/uluslararası
          mevzuatın izin verdiği ve/veya söz konusu mevzuat ya da taraf olunan
          sözleşmeler kapsamında kişisel bilgileri talep etme ve işleme iznine,
          hak ve yetkisine sahip kişi veya kuruluşlar (Adli ve idari makamlar,
          diğer resmi kurum ve kuruluşlar ve kişiler) başta olmak ve bunlarla
          sınırlı olmamak üzere, dolaylı/doğrudan yurtiçi/yurtdışı
          iştiraklerimiz ya da bağlı ortaklıklarımız, BulbeniQR tarafından
          hizmet/destek/danışmanlık alınan ya da işbirliği yapılan ya da
          proje/program/finansman ortağı olunan yurtiçi/yurtdışı/uluslararası,
          kamu/özel kurum ve kuruluşlar, şirketler ve sair 3. Kişi ya da
          kuruluşlara aktarılabilecektir.
          <br />
          <br />
          Kişisel verilerin toplanma yöntemi ve hukuki sebebi
          <br />
          <br />
          BulbeniQR ile paylaşılan kişisel veriler, BulbeniQR gözetimi ve
          kontrolü altındadır. BulbeniQR, yürürlükteki ilgili mevzuat hükümleri
          gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla
          gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak
          konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu
          konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan
          uluslararası ve ulusal teknik standartlara uygun surette periyodik
          aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme
          politikalarımızı her zaman güncellediğimizi bilginize sunarız.
          <br />
          <br />
          BulbeniQR kontrolü altında olmayan başka internet sitelerine bağlantı
          veya referans içerebilir. Bu bağlantıların BulbeniQR’da yer alması,
          bizim bu siteler ile herhangi bir bağımız bulunduğu anlamına gelmez.
          BulbeniQR, işbu internet sitelerinin içerikleri veya içerdikleri diğer
          bağlantılardan sorumlu değildir. Bu diğer internet siteleri
          kullanıcıların bilgisayarına kendi çerezlerini yükleyebilir ve bilgi
          toplayabilirler. Diğer internet siteleri kendi gizlilik ve kullanım
          sözleşmelerine sahiptir ve kullanıcılar tarafından paylaşılan
          bilgileri farklı amaçlar için kullanabilirler. BulbeniQR’ın tavsiyesi
          diğer internet sitelerini kullanmadan önce kullanıcıların
          sözleşmelerini okumalarıdır. Söz konusu siteler de, sizin kişisel
          bilgilerinizi toplayıp, işleyebilir. Bu gibi durumlarda; BulbeniQR,
          kendi dışındaki web sitelerinin sizin kişisel verilerinizi edinip,
          işlemesinden dolayı herhangi bir sorumluğu bulunmamaktadır. Bu amaçla,
          sizin; ilgili web sitelerinin gizlilik ve kişisel verilerin korunması
          ile ilgili sayfalarını okumanız gerekmektedir.
          <br />
          <br />
          Yukarıda açıklanan hukuki sebeplerle, kişisel verileriniz BulbeniQR’ın
          Genel Müdürlük Birimleri, Şubeleri, internet şubesi ve benzeri diğer
          tüm kanalları aracılığıyla otomatik ya da otomatik olmayan yollarla
          sözlü, yazılı veya elektronik olarak toplanabilmektedir.
          <br />
          <br />
          KVKK’nın “İstisnalar” başlıklı md. 28’de öngörülen haller saklı kalmak
          kaydıyla, anılan Kanun’un md. 11 kapsamında sahip olunan haklar:
          <br />
          <br />
          <ul>
            <li>- KVKK uyarınca kişisel verilerinizin;</li>
            <li>- İşlenip işlenmediğini öğrenme,</li>
            <li>- İşlenmişse bilgi talep etme,</li>
            <li>
              - İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını
              öğrenme,
            </li>
            <li>- Yurt içinde/yurt dışında aktarıldığı 3. kişileri bilme,</li>
            <li>- Eksik/yanlış işlenmişse düzeltilmesini isteme,</li>
            <li>
              - KVKK md. 7’de öngörülen şartlar çerçevesinde silinmesini/yok
              edilmesini isteme,
            </li>
            <li>
              - Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri
              uyarınca yapılan işlemlerin bildirilmesini isteme,
            </li>
            <li>
              - Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
              aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
            </li>
            <li>
              - KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız
              hâlinde zararın giderilmesini talep etme haklarına sahip
              olduğunuzu hatırlatmak isteriz.
            </li>
          </ul>
          <br />
          <br />
          BulbeniQR’a başvurarak kişisel verilerinizle ilgili bu bilgileri
          almaya hak ve yetkimiz olduğunuz hatırlatırız. Bu taleplerinizin
          yerine getirilmesini teminen, Netger tarafından yapılacak masrafları,
          KVKK’nın “Veri sorumlusuna başvuru” başlıklı md. 13’de belirtilen
          tarifeye göre tarafınızdan talep etme hakkımız saklıdır.
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}
